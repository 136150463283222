import React, { useState, useEffect } from "react";
import axios from 'axios'
import { PhotoAlbum, RenderContainer, RenderPhoto, RenderRowContainer } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import { StackedCarousel } from 'react-stacked-carousel'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import QRCode from "react-qr-code";
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import "./Desarrollos.css";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import bed from '../img/icons/bed_b.png'
import land from '../img/icons/land_b.png'
import build from '../img/icons/build_b.png'
import parking from '../img/icons/parking_b.png'
import bath from '../img/icons/bath_b.png'
import room from '../img/icons/room.png'
import location from '../img/icons/location.png'

import logo from '../img/logo-inmobi-gold.png'
import logo_b from '../img/logo-inmobi.png'
import inmobi from "../img/print-logo.png";
import search from '../img/search.png'
import line from '../img/line.png'
import lineb from '../img/lineb.png'
import rrss from '../img/rrss.png'
import sello from '../img/garantia.png'
import correoIcon from '../img/icons/mail.png'
import whatsIcon from '../img/icons/whats.png'
import webIcon from '../img/icons/web.png'
import { ArrowDown, ArrowLeftShort, ArrowRightShort, Facebook, Instagram, Linkedin, TelephoneFill, Tiktok, Whatsapp } from "react-bootstrap-icons";

import foto1 from '../img/ejemplo/foto1.jpg'
import foto2 from '../img/ejemplo/foto2.jpg'
import foto3 from '../img/ejemplo/foto3.jpg'
import foto4 from '../img/ejemplo/foto4.jpg'
import foto5 from '../img/ejemplo/foto5.jpg'
import foto6 from '../img/ejemplo/foto6.jpg'

const EASY_PATH = "https://inmobiplace.com/srv/properties.do";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const unsplashPhotos = [
  { src: foto1, width: 478, height: 646 },
  { src: foto2, width: 482, height: 309 },
  { src: foto3, width: 482, height: 309 },
  { src: foto4, width: 486, height: 199 },
  { src: foto5, width: 486, height: 199 },
  { src: foto6, width: 486, height: 199 },
];

const sizes = [
  { width: 478, height: 646 },
  { width: 482, height: 309 },
  { width: 482, height: 309 },
  { width: 486, height: 199 },
  { width: 486, height: 199 },
  { width: 486, height: 199 },
];

function Detail() {
  const [id, setId] = useState( window.location.pathname.split("/")[2] );
  const [ property, setProperty ] = useState(null)
  const [ photos, setPhotos ] = useState(null)
  const [ photoBase, setPhotoBase ] = useState([])
  const [ allPhotos, setAllPhotos ] = useState(null)
  const [ show, setShow ] = useState(false);
  const [ showFicha, setShowFicha ] = useState( false )

  const [ markerPoint, setMarkerPoint ] = useState('')
  const [ lat, setLat ] = useState(null)
  const [ lng, setLng ] = useState(null)
  const [ winHeight, setWinHeight ] = useState( 0 )
  const [ isLoading, setLoading ] = useState(false)

  const [lightIndex, setLightIndex] = useState(-1)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const containerStyle = {
    width: '100%',
    height: '350px'
  };

  useEffect( () => {
    handleOnSearchProperty()
  }, []);

  const load = url => new Promise( resolve => {
    const img = new Image()
    img.onload = () => resolve({ url, width: img.naturalWidth, height: img.naturalHeight })
    img.src = url
  });
  
  const handleOnSearchProperty = () => {

    axios.post(EASY_PATH + "?pid=" + 'EB-' + id, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'aW5tb2JpQW5kbWlu',
          'Access-Control-Allow-Origin': '*'
        }
      }).then( async function(response) {
        console.log('property', response.data)
        var images = []
        var gallery = []
        var base = []
        var limit = 6

        if( response.data.property_images.length < 6 ) limit = response.data.property_images.length

        /*for( var i = 0; i < limit; i++) {
          const { url, width, height } = await load(response.data.property_images[i].url);
          images.push( { src: url, width: width, height: height } )
        }*/

        for (var i = 0; i < limit; i++) {
          var item = response.data.property_images[i]
          images.push( { src: item.url, width: sizes[i].width, height: sizes[i].height } )
        }

        response.data.property_images.map( (item, index) => {
          gallery.push( { src: item.url, width: 1024, height: 768 } )
        })

        /*for( var i = 0; i < response.data.base64.length; i++) {
          const { url, width, height } = await load( 'data:image/png;base64,' + response.data.base64[i]);
          base.push( { src: url, width: width, height: height } )
        }*/
        /*response.data.base64.map( (item) => {
          base.push( { src: 'data:image/png;base64,' + item, width: 1024, height: 768 } )
        })*/
        
        setProperty( response.data )
        //setPhotoBase( base )
        setPhotos( images )
        setAllPhotos( gallery )
        setLat( response.data.location.latitude )
        setLng( response.data.location.longitude )
        
      }).catch(function(err) {
        console.log("error", err)
      });
  }

  const getBaseImages = () => {
    setLoading( true )

    axios.post(EASY_PATH + "?pid=" + 'EB-' + id + '&print=true', {}, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'aW5tb2JpQW5kbWlu',
        'Access-Control-Allow-Origin': '*'
      }
    }).then( async function(response) {
      var base = []

      for( var i = 0; i < response.data.base64.length; i++) {
        const { url, width, height } = await load( 'data:image/png;base64,' + response.data.base64[i]);
        base.push( { src: url, width: width, height: height } )
      }
      
      setPhotoBase( base )
      setShowFicha( true )
      setLoading( false )
    }).catch(function(err) {
      console.log("error", err)
    });
  }

  const handleMarker = () => {
    console.log("click")
    window.open("https://maps.google.com?q="+lat+","+lng );
    /*window.open("https://goo.gl/maps/toyoTx1oNh7Ghb446")*/
  }

  const onLoad = map => {
    setTimeout(() => {
      setMarkerPoint(<Marker position={ { lat: lat, lng: lng } } onClick={ handleMarker }/>)
    }, 1000);
  }

  const onChangeSlider = ( e ) => {
  }

  const printPDF = () => {
    console.log('setting loading')

    const infoPageElement = document.getElementById("App");
    const planoPageElement = document.getElementById("photos");
    var w = infoPageElement.offsetWidth
    var h = infoPageElement.offsetHeight
    var w2 = planoPageElement.offsetWidth
    var h2 = planoPageElement.offsetHeight

    //const pdf = new jsPDF('p', 'mm', 'a4');
    const pdf = new jsPDF('p', 'px', [w, h]);

    html2canvas(infoPageElement, {
      //onclone: document => {},
      /*onclone: async (_, infoPageElement) => {
        const images = infoPageElement.querySelectorAll('img')
        for await (const img of images) {
            if (img.src.includes('data:image')) continue
            img.src = await getBase64FromUrl(img.src)
        }
      },*/
      scale:3,
      useCORS: true,
      allowTaint: true,
    }).then(canvas => {
      //pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 5, 5, 200, 288);
      pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 0, w, h);
      pdf.addPage();

      html2canvas(planoPageElement, {
        /*onclone: async (_, planoPageElement) => {
          const images = planoPageElement.querySelectorAll('img')
          for await (const img of images) {
              if (img.src.includes('data:image')) continue
              img.src = await getBase64FromUrl(img.src)
          }
        },*/
        scale:3,
        useCORS: true,
        allowTaint: true,
      }).then(canvas => {
        pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 0, w2, h2);
        pdf.save(`ficha_tecnica_${ id }_${new Date().toISOString()}.pdf`);
        console.log("finish")
        setShowFicha(false)

      });
    });

  };

  /*const getBase64FromUrl = async (url) => {
    const data = await fetch(url, { mode: 'no-cors', cache: 'no-cache', headers: { 'Access-Control-Allow-Origin': '*' } });
    const blob = await data.blob();
    console.log("blob", blob)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  }*/

  return (
    <div style={ { position: 'relative' } }>
      { property && photos &&
        <div>
          <div className="gray-background" />

          <Container>
            <Row className="detail-row "></Row>
            <Row className="detail-row">
              <Col md={ 12 }>
                <div className="detail-title">{ property.title }</div>
              </Col>
            </Row>
            <Row className="detail-row">
              <Col>
                {/*<PhotoAlbum className='bg-album' layout="columns" photos={ photos } columns={ 3 } onClick={({ index }) => setLightIndex(index)} 
                    renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                        <div style={{ position: "relative", ...wrapperStyle }}>
                            { renderDefaultPhoto({ wrapped: false }) }
                            <div className='photo-desc'>
                                <div style={{ textTransform: "uppercase", textAlign: 'right' }} className="title-item">
                                    {photo.title}
                                </div>  
                                <div className='subtitle-item'>
                                    {photo.subtitle}
                                </div>
                            </div> 
                        </div>
                    )}
                />
                <br/>*/}

                <PhotoAlbum className='bg-album' layout="columns" photos={ photos } columns={ 3 } onClick={({ index }) => setLightIndex(index)}
                  renderPhoto={({ imageProps: { src, alt, style, index, ...restImageProps } }) => (
                    <div style={{ width: '100%', backgroundImage: 'url(' + src + ')', backgroundSize: 'cover', marginBottom: '0px', cursor: 'pointer'  }} onClick={() => restImageProps.onClick() }>
                      <img src={src} alt={alt} style={{ ...style, visibility: 'hidden', margin:0 }} {...restImageProps} />
                    </div>
                  )}
                />

              <Lightbox
                slides={ allPhotos }
                open={lightIndex >= 0}
                index={lightIndex}
                close={() => setLightIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
              />
              </Col>
            </Row>
            <Row className="align-items-center" style={{ backgroundColor: '#033F51' }}>
              <Col md={ 4 }>
                <div className="info-container">
                  <div className="line-subtitle">M<sup>2</sup> de terreno: </div>
                  <div className="line-title">{ property.lot_size } m<sup>2</sup></div>
                </div>
              </Col>
              <Col md={ 4 } className="line-left">
                <div className="info-container">
                  <div className="line-subtitle">Tipo de propiedad: </div>
                  <div className="line-title">{ property.property_type }</div>
                </div>
              </Col>
              <Col md={ 4 } className="line-left">
                <div className="info-container">
                  <div className="line-subtitle">Ubicación: </div>
                  <div className="line-title">{ property.location.name.split(",")[2] }</div>
                </div>
              </Col>
            </Row>

            <Row className="detail-row"></Row>
            <Row className="detail-row align-items-center">
              <Col md={ 6 } className="">
                <div className="detail-info-title">Propiedad</div>
                <div className="detail-info-price">{ property.operations[0].formatted_amount + ' ' + property.operations[0].currency }</div>
                <br/>
                <div className="detail-info-title2">Características</div>
                <div>
                  <Container>
                    <Row>
                      { property.bedrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.bedrooms } recámaras</span></div> </Col> }
                      { property.bathrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.bathrooms } baños</span></div> </Col> }
                      { property.parking_spaces &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.parking_spaces } estacionamientos</span></div> </Col> }
                      { property.construction_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.construction_size } m² de construcción</span></div> </Col> }
                      { property.lot_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.lot_size } m² de terreno</span></div> </Col> }
                    </Row>
                  </Container>
                </div>
                <br/>
                <div className="align-btn-left"> 
                  <div className="detail-info-button">
                    <span>Detalles</span>
                  </div>
                </div>
                <br/>
                <div className="detail-description">
                  <div>{ property.description }</div>
                  <br/>
                  <div>
                    <Container>
                      <Row>
                        { property.features.map( item => (
                          <Col md={ 6 } xs={ 12 } key={ item.name } style={{ marginBottom: '10px' }}>
                            <Container fluid>
                              <Row>
                                <Col lg={ 'auto' } md={2} xs={ 1 } className="no-padding">
                                <div> <img src={ room } className="hight-lights-icon" /> </div>
                                </Col>
                                <Col lg={ 'auto' } md={10} xs={ 11 }style={{ padding: '0 0 0 10px', lineHeight: '15px' }}>
                                  <span className="item-desc">{ item.name }</span>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        )) }
                      </Row>
                    </Container>
                  </div>
                </div>
                <br/>
                {/* <div align="left"> <span className="detail-info-button">Descargar ficha Técnica</span></div> */}
                <div align="left"> 
                  <div className="detail-info-button"> CONOCE LA UBICACIÓN </div>
                </div>
                <br/>
                <div className="clearfixe-body">
                  {/* <LoadScript googleMapsApiKey="AIzaSyAlRrxNFLSDHNRgRUNAjDocYC8No8NZWL4">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{ lat: lat, lng: lng }}
                      zoom={ 16 }
                      onLoad = { onLoad }
                    >
                      { markerPoint }

                    </GoogleMap>
                    </LoadScript>*/}
                    <Container>
                      <Row>
                        <Col lg={ 7 } md={ 12 } xs={ 12 }>
                          <iframe src={ 'https://maps.google.com/maps?q=' + lat + ',' + lng + '&z=15&output=embed' } width="100%" height="254px" frameborder="0"></iframe>
                        </Col>
                        <Col lg={ 5 } md={ 12 } xs={ 12 }>
                          <div onClick={ () => handleMarker() } style={{ cursor: 'pointer' }} className="footer-map-form">
                            <img src={ search } style={{ height: '254px' }} />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                </div>
              </Col>
              <Col md={ 6 }>
                <div>
                  <StackedCarousel
                    autoRotate={ true }
                    rotationInterval={ 4000 }
                    onCardChange={ (e) => onChangeSlider( e ) }
                    containerClassName="stacked-carousel"
                    cardClassName="stacked-card"
                    leftButton={<div> <ArrowLeftShort className="arrow-carousel"/> </div>}
                    rightButton={<div style={{ position: 'relative' }} className="carousel-right-arrow"> <ArrowRightShort className="arrow-carousel"/> </div>}
                  >
                    {property.property_images.map(( d, idx ) => (
                      <div key={ idx } className="stacked-card" style={{ backgroundImage: 'url(' + d.url + ')', cursor: 'pointer' }} onClick={ () => setLightIndex(idx) }></div>
                    ))}
                  </StackedCarousel>
                </div>
                <br/><br/><br/>

                <div align="center" className="btn-download"> 
                  <div><button disabled={ isLoading } className="detail-info-button" onClick={ () => getBaseImages() } >{ isLoading ? 'GENERANDO FICHA...' : 'DESCARGAR FICHA TÉCNICA' }</button></div>
                  {/* <div className="detail-info-button">DESCARGAR FICHA TÉCNICA</div> */}
                </div>
                {/* <br/>
                <div className="detail-info-title2">Ubicación</div>
                <br/>
                <div className="clearfixe-body">
                  <LoadScript googleMapsApiKey="AIzaSyAlRrxNFLSDHNRgRUNAjDocYC8No8NZWL4">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{ lat: lat, lng: lng }}
                      zoom={ 16 }
                      onLoad = { onLoad }
                    >
                      { markerPoint }

                    </GoogleMap>
                  </LoadScript>
                </div> */}
              </Col>
            </Row>
          </Container>

          <Modal show={showFicha} onHide={ () => setShowFicha(false) } style={{ maxWidth: 'none' }}>
            <Modal.Header style={{ background: "#fff" }} closeButton></Modal.Header>
            <Modal.Body>
              
              { photoBase.length > 0 && 
              <>
              <div id="App" className="app-container">
                <div id="infoApp" className="app">
                  
                  <div className="gray-background"/>
                  <Container fluid>
                    <br/>
                    <Row className="align-items-center">
                      <Col md={ 5 }>
                        <div className="print-logo-container">
                          <img src={ inmobi } className="print-logo" />
                        </div>
                      </Col>
                      <Col md={ 7 }>
                        {/* <div className="print-id-container"> ID DE LA PROPIEDAD: { property.public_id.replace("EB-", "") }</div> */}
                        <div align="center"> <img src={ lineb } className="print-line"/> </div>
                      </Col>
                    </Row>
                    <br/><br/>
                    <Row>
                      <Col>
                        <div className="detail-title" style={{ fontSize: '45px', lineHeight: '50px', fontFamily: 'GorditaBlack' }}>{ property.title }</div>
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      { photoBase.slice(0, 3).map( item => (
                        <Col md={ 4 }>
                          <div className="print-main-imgs" style={{ backgroundImage: 'url(' + item.src + ')' }} />
                        </Col>
                      ))}
                    </Row>
                    <br/>
                    <Row>
                      <Col md={ 7 } className="p-4">
                        {/*<div className="detail-title" style={{ fontSize: '35px', lineHeight: '40px', textAlign: 'left', fontFamily: 'GorditaBlack' }}>{ property.location.name }</div>*/}
                        <br/>
                        <div className="detail-info-price">{ property.operations[0].formatted_amount + ' ' + property.operations[0].currency }</div>
                        <br/>
                        <div className="detail-info-title2">Descripción</div>
                        <div>
                          <Container>
                            <Row>
                              { property.bedrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bed } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.bedrooms } recámaras</span></div> </Col> }
                              { property.bathrooms &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ bath } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.bathrooms } baños</span></div> </Col> }
                              { property.parking_spaces &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ parking } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.parking_spaces } estacionamientos</span></div> </Col> }
                              { property.construction_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ build } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.construction_size } m² de construcción</span></div> </Col> }
                              { property.lot_size > 0 &&  <Col md='auto' className="mb-1 mt-1"> <div> <img src={ land } className="hight-lights-icon" />&nbsp;&nbsp;<span className="hight-lights-text">{ property.lot_size } m² de terreno</span></div> </Col> }
                            </Row>
                          </Container>
                        </div>
                        <br/>
                        <div className="align-btn-left"> 
                          <div className="detail-info-button">
                            <span>Amenidades</span>
                          </div>
                        </div>
                        <br/>
                        <div className="detail-description">
                          <div>
                            <Container>
                              <Row>
                                { property.features.map( item => (
                                  <Col md={ 6 } key={ item.name }>
                                    <Container fluid>
                                      <Row>
                                        <Col md={ 'auto' } className="no-padding">
                                        <div> <img src={ room } className="hight-lights-icon" /> </div>
                                        </Col>
                                        <Col style={{ padding: '0 0 0 10px' }}>
                                          <span className="item-desc">{ item.name }</span>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Col>
                                )) }
                              </Row>
                            </Container>
                          </div>
                        </div>
                        <br/>
                        <div>
                          <img src={ sello } style={{ width: '100%' }}/>
                        </div>
                      </Col>
                      <Col md={ 5 }>
                        <br/><br/>
                        <div className="print-carousel-style">
                          <div className="print-main-imgs-v2" style={{ backgroundImage: 'url(' + photoBase[3].src + ')' }} />
                        </div>
                        <br/>
                        <div className="align-btn-left"> 
                          <div className="detail-info-button">
                            <span>Ubicación</span>
                          </div>
                        </div>
                        <br/>
                        <div style={{ fontWeight: 'bolder' }}> <img src={ location } className="hight-lights-icon" />&nbsp; { property.location.street ? property.location.street : '' } { property.location.interior_number ? property.location.interior_number + ',' : '' } { property.location.exterior_number ? ' Ext: ' + property.location.exterior_number + ',' : '' } { property.location.name ? property.location.name + ',' : '' } { property.location.postal_code ? ' C.P. ' + property.location.postal_code : '' } </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col md={ 4 }>
                        <div className="print-main-imgs" style={{ backgroundImage: 'url(' + photoBase[ photoBase.length - 4 ].src + ')' }} />
                      </Col>
                      <Col md={ 4 }>
                        <Container fluid >
                          <Row>
                            <Col className="p0"> <div className="print-main-imgs-v3" style={{ backgroundImage: 'url(' + photoBase[ photoBase.length - 3 ].src + ')' }} /> </Col>
                          </Row>
                          <div style={{ height: '10px' }} />
                          <Row>
                            <Col className="p0"> <div className="print-main-imgs-v3" style={{ backgroundImage: 'url(' + photoBase[ photoBase.length - 2 ].src + ')' }} /> </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col md={ 4 }>
                        <div className="print-main-imgs" style={{ backgroundImage: 'url(' + photoBase[ photoBase.length - 1 ].src + ')' }} />
                      </Col>
                    </Row>
                    <br/><br/>
                    <Row className="align-items-center">
                      <Col md={ 4 }> </Col>
                      <Col md={ 4 }>
                        <div className="print-card-container">
                          <div className="print-card-title">CONTÁCTANOS</div>
                          <div><img src={ correoIcon } className="icon-style-img" /> &nbsp; <span style={{ color:'#000' }}>contacto@inmobiplace.com</span> </div>
                          <div><img src={ whatsIcon } className="icon-style-img" /> &nbsp; <span style={{ color:'#000' }}>56 4002 5428</span> </div>
                          <div><img src={ webIcon } className="icon-style-img" /> &nbsp; <span style={{ color:'#000' }}>inmobiplace.com</span> </div>
                        </div>
                      </Col>
                      <Col md={ 4 }> 
                        <div className="print-card-container">
                          <Container>
                            <Row className="align-items-center">
                              <Col md={ 6 }>
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%", backgroundColor: '#fff', padding: '5px' }}>
                                  <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={ 'https://maps.google.com?q=' + lat + ',' + lng }
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </Col>
                              <Col md={ 6 }>
                                <div className="print-card-title"> OBTÉN LA UBICACIÓN </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row className="align-items-center">
                      <Col md={ 5 }>
                        <div className="print-footer-container" align="center">
                          <img src={ rrss } className="print-line"/>
                        </div>
                      </Col>
                      <Col md={ 7 }>
                        <div align="center"> <img src={ line } className="print-line"/> </div>
                      </Col>
                    </Row>



                    {/*<Row>
                      <Col md={ 5 }>
                        <div align="center"> <img src={ logo } className="logo-80" /> </div>
                        <br/><br/>
                        <div className="print-hight-container">
                          { ( property.bedrooms && property.bedrooms > 0 ) &&
                            <>
                              <div className="print-hight-number"> { property.bedrooms } </div>
                              <div className="print-hight-title"> Recámaras </div>
                              <div align="center"> <div className="print-hight-line" /> </div>
                            </>
                          }
                          { ( property.bathrooms && property.bathrooms > 0 ) &&
                            <>
                              <div className="print-hight-number"> { property.bathrooms } </div>
                              <div className="print-hight-title"> Baños </div>
                              <div align="center"> <div className="print-hight-line" /> </div>
                            </>
                          }
                          { ( property.parking_spaces && property.parking_spaces > 0) &&
                            <>
                              <div className="print-hight-number"> { property.parking_spaces } </div>
                              <div className="print-hight-title"> Estacionamientos </div>
                              <div align="center"> <div className="print-hight-line" /> </div>
                            </>
                          }
                          { ( property.construction_size > 0 ) &&
                            <>
                              <div className="print-hight-number"> { property.construction_size } </div>
                              <div className="print-hight-title"> m² de construcción </div>
                              <div align="center"> <div className="print-hight-line" /> </div>
                            </>
                          }
                          { ( property.lot_size &&  property.lot_size > 0 ) &&
                            <>
                              <div className="print-hight-number"> { property.lot_size } </div>
                              <div className="print-hight-title"> m² de terreno </div>
                              <div align="center"> <div className="print-hight-line" /> </div>
                            </>
                          }
                          
                          <br/>
                          <div className="prit-price">
                            <div> Precio </div>
                            <div> { property.operations[0].formatted_amount } </div>
                          </div>

                        </div>
                      </Col>

                      <Col md={ 7 }>
                        <div className="print-prop-title"> { property.title }</div>
                        <br/>
                        <div className="print-prop-location"> { property.location.name }</div>
                        <div className="print-prop-location"> ID DE LA PROPIEDAD: { property.public_id.replace("EB-", "") }</div>
                        <br/>
                        <div className="print-prop-desc"> { property.description }</div>
                      </Col>
                    </Row>*/}
                  </Container>

                  {/* <Container fluid className="print-footer">
                    <Row className="align-items-center">
                      <Col md={ 6 }>
                        <div className="prit-price" align="center">
                          <div> GALERÍA DE FOTOS </div>
                        </div>
                        <br/>
                        <div align="center"> <ArrowDown className="print-icon" /> </div>
                      </Col>
                      <Col md={ 6 }>
                        <div className="print-qr-contaner">

                          <div className="print-qr-text"> Obtén la ubicación </div>
                          <div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%", backgroundColor: '#fff', padding: '5px' }}>
                            <QRCode
                              size={256}
                              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                              value={ 'https://maps.google.com?q=' + lat + ',' + lng }
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container> */}

                </div>
              </div>

              <div id="photos" className="app-container">
                <div id="infoPhotos" className="app">
                  <Container fluid>
                    <br/>
                    <Row className="align-items-center">
                      <Col md={ 5 }>
                        <div className="print-logo-container">
                          <img src={ inmobi } className="print-logo" />
                        </div>
                      </Col>
                      <Col md={ 7 }>
                        <div align="center"> <img src={ lineb } className="print-line"/> </div>
                      </Col>
                    </Row>
                    <br/><br/>
                    <Row>
                      <Col>
                        <PhotoAlbum className='bg-album' layout="columns" photos={ photoBase } columns={ photoBase.length > 10 ? 5 : 3 }
                          renderPhoto={({ imageProps: { src, alt, style, ...restImageProps } }) => (
                            <img src={src} alt={alt} style={{ ...style }} {...restImageProps} />
                          )}
                        />
                      </Col>
                    </Row>
                    <br/><br/>
                    <Row className="align-items-center">
                      <Col md={ 4 }> </Col>
                      <Col md={ 4 }>
                        <div className="print-card-container">
                          <div className="print-card-title">CONTÁCTANOS</div>
                          <div><img src={ correoIcon } className="icon-style-img" /> &nbsp; <span style={{ color:'#000' }}>contacto@inmobiplace.com</span> </div>
                          <div><img src={ whatsIcon } className="icon-style-img" /> &nbsp; <span style={{ color:'#000' }}>56 4002 5428</span> </div>
                          <div><img src={ webIcon } className="icon-style-img" /> &nbsp; <span style={{ color:'#000' }}>inmobiplace.com</span> </div>
                        </div>
                      </Col>
                      <Col md={ 4 }> 
                        <div className="print-card-container">
                          <Container>
                            <Row className="align-items-center">
                              <Col md={ 6 }>
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%", backgroundColor: '#fff', padding: '5px' }}>
                                  <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={ 'https://maps.google.com?q=' + lat + ',' + lng }
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </Col>
                              <Col md={ 6 }>
                                <div className="print-card-title"> OBTÉN LA UBICACIÓN </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row className="align-items-center">
                      <Col md={ 5 }>
                        <div className="print-footer-container" align="center">
                          <img src={ rrss } className="print-line"/>
                        </div>
                      </Col>
                      <Col md={ 7 }>
                        <div align="center"> <img src={ line } className="print-line"/> </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              </>
              }
              
              {/* <br/>
              <div id="photos" className="app-container">
                <div id="infoPhotos" className="app">
                  <Container fluid className="">
                    <Row className="align-items-center">
                      <Col md={ 6 }>
                      </Col>
                      <Col md={ 6 }>
                        <div className="print-qr-contaner-header" align="center">
                          <img src={ logo_b } className="print-logo-white" />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <br/>
                  <div>
                    <br/>
                    <PhotoAlbum className='bg-album' layout="columns" photos={ photoBase ? photoBase.slice(0, 3) : [] } columns={ 2 }
                      renderPhoto={({ imageProps: { src, alt, style, ...restImageProps } }) => (
                        <img src={src} alt={alt} style={{ ...style, borderRadius: '50px 50px 50px 50px' }} {...restImageProps} />
                      )}
                    />
                    <br/>
                    <PhotoAlbum className='bg-album' layout="columns" photos={ photoBase ? photoBase.slice(3, 6) : [] } columns={ 2 }
                      renderPhoto={({ imageProps: { src, alt, style, ...restImageProps } }) => (
                        <img src={src} alt={alt} style={{ ...style, borderRadius: '50px 50px 50px 50px' }} {...restImageProps} />
                      )}
                    />

                  </div>

                  <Container fluid className="print-footer print-footer-color">
                    <Row className="align-items-center print-footer-text">
                      <Col md={ 4 }>
                        <div><TelephoneFill className="print-footer-icon" /> &nbsp; 5528391785 </div>
                        <div><Whatsapp className="print-footer-icon" /> &nbsp; 5625985051 </div>
                        <div> contacto@inmobiplace.com </div>
                      </Col>
                      <Col md={ 4 }>
                        <div className="print-footer-title"> CONTÁCTANOS </div>
                      </Col>
                      <Col md={ 4 }>
                        <div align="center">
                          <Facebook className="print-footer-rrss" /> &nbsp;
                          <Instagram className="print-footer-rrss" /> &nbsp;
                          <Tiktok className="print-footer-rrss" /> &nbsp;
                          <Linkedin className="print-footer-rrss" /> &nbsp;
                        </div>
                        <div align="center">
                          <div> www.inmobiplace.com </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div> */}

            </Modal.Body>
            <Modal.Footer style={{ color: "#000", background: "#fff" }}>
              <Button variant="secondary" onClick={() => printPDF() }>
                Descargar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      }

      <br/><br/>
    </div>
  );
}

export default Detail;
