import React, { useState, useEffect } from "react";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import axios from 'axios'
import { useInView } from 'react-intersection-observer';

import Footer from '../pages/Footer';

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'

import Slider from "react-slick";
import { StackedCarousel } from 'react-stacked-carousel'
import { Carousel as Carusel } from 'primereact/carousel';

import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';

import "./Desarrollos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-stacked-carousel/dist/index.css';

import "../titleCSS/title01.css";
import "../titleCSS/title02.css";
//import "../titleCSS/title03.css";
import "../titleCSS/title04.css";
import "../titleCSS/title05.css";

import us1 from '../img/us_1.png'

import destacado1 from '../img/destacado1.png'
import destacado2 from '../img/destacado2.png'
import { ArrowLeft, ArrowLeftCircle, ArrowLeftShort, ArrowRightShort, Envelope, EnvelopeAtFill, TelephoneFill } from "react-bootstrap-icons";

import correoIcon from '../img/icons/correo.png'
import phoneIcon from '../img/icons/phone.png'
import youtubeIcon from '../img/icons/youtube.png'
import inIcon from '../img/icons/in.png'
import tikIcon from '../img/icons/tik.png'
import faceIcon from '../img/icons/face.png'
import instaIcon from '../img/icons/insta.png'

import contact from '../img/contact.png'

const AutoplaySlider = withAutoplay(AwesomeSlider);

const API_PATH = 'https://inmobiplace.com/api/contact/';
const SITE_KEY = "6LcSmUgiAAAAAEfRTieNZ7hc8jcfhGayD9mxUKYX";
const EASY_PATH = "https://inmobiplace.com/srv/properties.do";

const mainProperties = ["EB-OV6477", "EB-OW0561", "EB-PV3377"]

function Propiedades({ refUs, refContact }) {
  const [data, setData] = useState([ 'LAS MEJORES UBICACIÓNES', 'LOS MEJORES COSTOS', 'LAS MEJORES RESIDENCIAS' ]); 
  const [ activeForm, setActiveForm ] = useState(false)

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [ properties, setProperties ] = useState([])
  const [ original, setOriginal ] = useState([])
  const [ propertyTypeList, setPropertyTypeList ] = useState([])
  const [ categoryList, setCategoryList ] = useState([])
  const [ propertyType, setPropertyType ] = useState("")
  const [ category, setCategory ] = useState("")
  const [ mainPropertyList, setMainPropertyList ] = useState(null)
  const [ mainPropertyIds, setMainPropertyIds ] = useState(null)

  const [ propertyTitle, setPropertyTitle ] = useState("")
  const [ propertyDesc, setPropertyDesc ] = useState("")
  const [ propertyPrice, setPropertyPrice ] = useState("")
  const [ carouselActiveItem, setCarouselActiveItem ] = useState(0)
  const [ textActiveItem, setTextActiveItem ] = useState(0)

  const [card, setCard] = useState(null);

  const { ref, inView, entry } = useInView({ threshold: 0 });
  const [ refFoo, inViewFoo, entryFoo ] = useInView({ threshold: 0 });

  const onCardChange = (event) => {
    console.log("Card", event);
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 4300,
    pauseOnHover: false,
    beforeChange: function(currentSlide, nextSlide) {
      setTextActiveItem( nextSlide )
    },
    afterChange: function(currentSlide) {}
  };

  useEffect(() => {
    document.body.onscroll = function(){
      var bodyP = document.getElementById("properties-body").offsetHeight
      var footer = document.getElementById("footerComponent").offsetHeight
      var rrss = document.getElementById("in-cont")
      var rrss2 = document.getElementById("in-cont-2")

      if( window.scrollY > 2550){ //position of where you want the element to go + it's height
        rrss.style.position="absolute";
        rrss.style.top = (bodyP - footer) + 'px';
        rrss.style.bottom="";

        rrss2.style.position = "absolute";
        rrss2.style.top = (bodyP - footer) + 'px';
        rrss2.style.bottom = "";
      }
      else{
        rrss.style.position="fixed";
        rrss.style.bottom="40px";
        rrss.style.top="";

        rrss2.style.position="fixed";
        rrss2.style.bottom="40px";
        rrss2.style.top="";
      }
    }
  });

  useEffect( () => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });

    handleOnSearchProperties()
  }, []);

  useEffect( () => {
    if( mainPropertyIds ) {
      var map = []
      var params = ""

      mainPropertyIds.map( item => {
        params += item
      })

      axios.get(EASY_PATH + "?pid=" + params.slice(0, -1), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'aW5tb2JpQW5kbWlu',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(function(response) {
        response.data.map( item => {
          console.log( 'data item', JSON.stringify(item))
          map.push( { public_id: item.public_id, title: item.title, description: item.description, operations: item.operations, property_images: item.property_images } )
        })

        console.log( "result main list", map )
        setMainPropertyList( map )

      }).catch(function(err) {
        console.log("error", err)
      });
    }

  }, [mainPropertyIds]);

  useEffect(() => {
    if(inView) {
      document.getElementById("ig-icon").classList.add("float-icon-black");
      document.getElementById("fb-icon").classList.add("float-icon-black");
      document.getElementById("tk-icon").classList.add("float-icon-black");
      document.getElementById("yt-icon").classList.add("float-icon-black");
      document.getElementById("in-icon").classList.add("float-icon-black");
    } else {
      document.getElementById("ig-icon").classList.remove("float-icon-black");
      document.getElementById("fb-icon").classList.remove("float-icon-black");
      document.getElementById("tk-icon").classList.remove("float-icon-black");
      document.getElementById("yt-icon").classList.remove("float-icon-black");
      document.getElementById("in-icon").classList.remove("float-icon-black");
    }
  }, [inView])

  useEffect(() => {
    if(inViewFoo) {
      document.getElementById("ig-icon-foo").classList.remove("float-icon-hide");
      document.getElementById("fb-icon-foo").classList.remove("float-icon-hide");
      document.getElementById("tk-icon-foo").classList.remove("float-icon-hide");
      document.getElementById("yt-icon-foo").classList.remove("float-icon-hide");
      document.getElementById("in-icon-foo").classList.remove("float-icon-hide");
    } else {
      document.getElementById("ig-icon-foo").classList.add("float-icon-hide");
      document.getElementById("fb-icon-foo").classList.add("float-icon-hide");
      document.getElementById("tk-icon-foo").classList.add("float-icon-hide");
      document.getElementById("yt-icon-foo").classList.add("float-icon-hide");
      document.getElementById("in-icon-foo").classList.add("float-icon-hide");
    }
  }, [inViewFoo])

  {/*useEffect(() => {
    console.log("effect on")
    var result = original
    
    if(propertyType != "")
      result = result.filter(function(item) { return item.property_type === propertyType; });
    if (category != "")
      result = result.filter(function(item) { return item.operations[0].type === category; });

    setProperties(result)
  }, [propertyType, category]);*/}

  const handleOnSearchProperties = () => {
    var pType = [];
    var cat = []

    axios.post(EASY_PATH, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'aW5tb2JpQW5kbWlu',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(function(response) {
        var newList = []
        var mainList = []
        console.log("success", response.data.content)

        response.data.content.map( item => {
          cat.indexOf(item.operations[0].type) === -1 ? cat.push(item.operations[0].type) : console.log(".");
          pType.indexOf(item.property_type) === -1 ? pType.push(item.property_type) : console.log(".");

          /*if( mainProperties.includes(item.public_id)  ){ 
            item.sort = 1
            mainList += item.public_id + "/"
          } else item.sort = 100*/
          
          mainList.push( item.public_id + "/" )
          item.sort = 100
          newList.push(item)
        })

        newList = newList.sort((a,b) => a.sort - b.sort);
        console.log("newList", newList)

        console.log("result:", cat, pType, mainList)
        setCategoryList(cat)
        setPropertyTypeList(pType.sort())

        setMainPropertyIds( mainList.slice(0, 3) )
        setProperties(newList)
        setOriginal(newList)
      }).catch(function(err) {
        console.log("error", err)
      });
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
        setShow(false);
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const dataTemplate = (product) => {
    return (
      <div class="v-carousel-centered">
        <div class="vertical-center big_title">
          { product }
        </div>
      </div>
    );
  };

  const onChangeSlider = ( e ) => {
    var el = mainPropertyList[e.currentIndex]

    setCarouselActiveItem ( e.currentIndex )
    setPropertyTitle( el.title )
    setPropertyDesc( el.location ) 
    setPropertyPrice( el.operations[0].formatted_amount + ' ' + el.operations[0].currency )
  }

  return (
    <>
      <div>
        <div className="fl-fl" id="in-cont">
          <div>
            <a href={ "https://www.instagram.com/Inmobiplace/" } target="_blank">
              <span> <img id="ig-icon" src={ instaIcon } className="float-icon" /> </span>
            </a>
            <a href={ "https://www.facebook.com/inmobiplace/" } target="_blank">
              <span> <img id="fb-icon" src={ faceIcon } className="float-icon" /> </span>
            </a>
            <a href={ "https://www.tiktok.com/@inmobiplace" } target="_blank">
              <span> <img id="tk-icon" src={ tikIcon } className="float-icon" /> </span>
            </a>
            <a href={ "https://www.linkedin.com/company/inmobiplace/?viewAsMember=true" } target="_blank">
              <span> <img id="in-icon" src={ inIcon } className="float-icon" /> </span>
            </a>
            <a href={ "https://www.youtube.com/channel/UC5KgahHC2ioFzv4rPdwQvXA" } target="_blank">
              <span> <img id="yt-icon" src={ youtubeIcon } className="float-icon" /> </span>
            </a>
          </div>
        </div>

        <div className="fl-fl" id="in-cont-2">
          <div>
            <a href={ "https://www.instagram.com/Inmobiplace/" } target="_blank">
              <span> <img id="ig-icon-foo" src={ instaIcon } className="float-icon float-icon-hide" /> </span>
            </a>
            <a href={ "https://www.facebook.com/inmobiplace/" } target="_blank">
              <span> <img id="fb-icon-foo" src={ faceIcon } className="float-icon float-icon-hide" /> </span>
            </a>
            <a href={ "https://www.tiktok.com/@inmobiplace" } target="_blank">
              <span> <img id="tk-icon-foo" src={ tikIcon } className="float-icon float-icon-hide" /> </span>
            </a>
            <a href={ "https://www.linkedin.com/company/inmobiplace/?viewAsMember=true" } target="_blank">
              <span> <img id="in-icon-foo" src={ inIcon } className="float-icon float-icon-hide" /> </span>
            </a>
            <a href={ "https://www.youtube.com/channel/UC5KgahHC2ioFzv4rPdwQvXA" } target="_blank">
              <span> <img id="yt-icon-foo" src={ youtubeIcon } className="float-icon float-icon-hide" /> </span>
            </a>
          </div>
        </div>
      </div>

      <div id="properties-body">
        <div className="p-banner">
          <div className="p-search-container">
            <div className="p-title">Aprovecha, compra hoy</div>

            <div align="center">
              <div className="p-search">
                <Container>
                  <Row className="align-items-center">
                    <Col md={ 4 }>
                      <FloatingLabel label="¿Qué buscas?">
                        {/*<Form.Select aria-label="Floating label select example">
                          <option value="1">Comprar</option>
                          <option value="2">Rentar</option>
                        </Form.Select>*/}
                        <Form.Select onChange={ (e) => setCategory(e.target.value) }>
                          <option value="" >Sin filtro</option>
                          { categoryList.map(item => (
                              <option value={ item } id={ item } >{ item === 'sale' ? 'Venta' : 'Renta' }</option>
                          )) }
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={ 4 }>
                      <FloatingLabel label="Tipo de Propiedad">
                        {/*<Form.Select aria-label="Floating label select example">
                          <option value="1">Casa</option>
                          <option value="2">Departamento</option>
                          <option value="2">Oficina</option>
                        </Form.Select>*/}
                        <Form.Select onChange={ (e) => setPropertyType(e.target.value) }>
                          <option value="" >Sin filtro</option>
                          { propertyTypeList.map(item => (
                              <option value={ item } id={ item } >{ item }</option>
                          )) }
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={ 4 }>
                      <div align="center"> <div className="button-desa" style={{ width: '100%', backgroundColor: '#000' }} onClick={ () => window.location.href = '/desarrollos?offer=' + category + '&ptype=' + propertyType }>Buscar</div> </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

          </div>
        </div>

        <div className="p-container" ref={ refUs }>
          <Container>
            <Row className="align-items-center">
              <Col md={ 6 }>
                <div style={{ position: 'relative' }}>
                  <div className="p-body-title-border"></div>
                  <div className="p-body-title-back"></div>
                  <Container>
                    <Row className="align-items-center">
                      <Col><div className="p-body-title"> NOSOTROS </div></Col>
                    </Row>
                  </Container>
                </div>

                {/* <div class="big_title" style={{ textAlign: 'left' }} >
                  NOSOTROS
                </div> */}
              </Col>
              <Col md={ 6 }>
                <hr style={{ color: '#fff', opacity: 1 }}/>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={ 5 }>
                <Container className="us-container">
                  <Row>
                    <Col>
                      <div className="p-body-desc"> Somos especialistas en comercialización inmobiliaria para negocios, residencias e industrias. Con un equipo experimentado, nuestro compromiso será encontrar el espacio ideal para alcanzar tus metas. </div>
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col md={ 7 }>
                {/*<Carusel value={ data } numVisible={1} numScroll={1} orientation="vertical" verticalViewPortHeight="360px" autoplayInterval={3000} itemTemplate={ dataTemplate } />*/}
                <Slider {...settings}>
                  
                  {/*<div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                        <div class="words word-1">
                          <span>A</span>
                          <span>S</span>
                          <span>E</span>
                          <span>S</span>
                          <span>O</span>
                          <span>R</span>
                          <span>E</span>
                          <span>S</span>
                        </div>

                        <div class="words word-2">
                          <span>C</span>
                          <span>E</span>
                          <span>R</span>
                          <span>T</span>
                          <span>I</span>
                          <span>F</span>
                          <span>I</span>
                          <span>C</span>
                          <span>A</span>
                          <span>D</span>
                          <span>O</span>
                          <span>S</span>
                        </div>
                      </div>
                    </div>
                  </div>*/}

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                        { textActiveItem == 0 &&
                          <section class="t4-container">
                            <h1 class="title">
                              <span>Asesores</span>
                              <span>Certificados</span>
                            </h1>
                          </section>
                        }
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                        { textActiveItem == 1 &&
                          <section class="t4-container">
                            <h1 class="title">
                              <span>Alianzas</span>
                              <span>Inmobiliarias</span>
                            </h1>
                          </section>
                        }
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                        { textActiveItem == 2 &&
                          <div className="parrp"> Comercial </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                        { textActiveItem == 3 &&
                          <div className="parrp"> Residencial </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                      { textActiveItem == 4 &&
                        <div className="parrp"> Industrial </div>
                      }
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center" align="center">
                      { textActiveItem == 5 &&
                        <div class="t01-container">
                          <div class="t01-upper big_title">ASOCIADOS</div>
                          <div class="t01-lower big_title">ASOCIADOS</div>
                          <div class="t01-inside">AMPI</div>
                        </div>
                      }
                      </div>
                    </div>
                  </div>

                  {/*<div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center big_title">
                        <section class="t4-container">
                          <h1 class="title">
                            <span>Rendimientos</span>
                            <span>garantizados</span>
                          </h1>
                        </section>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center" align="center">
                        <div class="t01-container">
                          <div class="t01-upper big_title">4.8%</div>
                          <div class="t01-lower big_title">4.8%</div>
                          <div class="t01-inside">de crecimiento</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center" align="center">
                        <div class="t01-container">
                          <div class="t01-upper big_title">5.26</div>
                          <div class="t01-lower big_title">5.26</div>
                          <div class="t01-inside">MDD en ventas</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="v-carousel-centered">
                      <div class="vertical-center" align="center">
                        <div class="t01-container">
                          <div class="t01-upper big_title">8</div>
                          <div class="t01-lower big_title">8</div>
                          <div class="t01-inside">años continuos</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  */}

                  {/*<div>
                    <div class="v-carousel-centered">
                      <div class="wrapper big_title">
                        <span>C</span>
                        <span>O</span>
                        <span>M</span>
                        <span>E</span>
                        <span>R</span>
                        <span>C</span>
                        <span>I</span>
                        <span>A</span>
                        <span>L</span>
                      </div>
                    </div>
                  </div>*/}

                </Slider>
              </Col>
            </Row>
          </Container>
        </div>

        <div ref={ ref }>
        <div className="p-container" style={{ background: '#fff' }}>
          <Container>
            <Row className="align-items-center">
              {/* <Col>
                <div align="center">
                  <div className="p-body-title-blue">
                    DESTACADOS
                  </div>
                </div>
              </Col> */}
              <Col md={ 6 }>
                <div style={{ position: 'relative' }}>
                  <div className="p-body-title-border-blue"></div>
                  <div className="p-body-title-back-blue"></div>
                  <Container>
                    <Row className="align-items-center">
                      <Col><div className="p-body-title-blue"> DESTACADOS </div></Col>
                    </Row>
                  </Container>
                </div>

                {/* <div class="big_title" style={{ textAlign: 'left' }} >
                  NOSOTROS
                </div> */}
              </Col>
              <Col md={ 6 }>
                <hr style={{ color: '#2E8FAC', opacity: 1 }}/>
              </Col>
            </Row>
            <br/><br/><br/>
            <Row>
              <Col md={ 5 }>
                {/* <div className="carousel-info">
                  <div className="main-title carousel-text-animation">{ propertyTitle }</div>
                  <div className="main-desc carousel-text-animation">
                    { propertyDesc }
                    </div>
                  <div className="main-price carousel-text-animation">{ propertyPrice }</div>
                </div> */}

                <Carousel fade={ false } controls={ false } indicators={ false } activeIndex={ carouselActiveItem } wrap={ true }>
                  { mainPropertyList && mainPropertyList.map((item) => (
                    <Carousel.Item style={{ backgroundColor: '#fff' }} onClick={ () => window.open(`/desarrollos/${ item.public_id.split("-")[1] }`, "_self")}>
                      <div className="carousel-info">
                        <div className="main-title carousel-text-animation">{ item.title }</div>
                        <div className="main-desc carousel-text-animation">
                          { item.description }
                        </div>
                        <br/><br/>
                        <div className="main-price carousel-text-animation">{ item.operations[0].formatted_amount + " " + item.operations[0].currency }</div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
              <Col md={ 7 }>
              
              { mainPropertyList != null && 
                <>
                  <StackedCarousel
                    autoRotate={ true }
                    rotationInterval={ 6000 }
                    onCardChange={ (e) => onChangeSlider( e ) }
                    containerClassName="stacked-carousel"
                    cardClassName="stacked-card"
                    leftButton={<div> <ArrowLeftShort className="arrow-carousel"/> </div>}
                    rightButton={<div style={{ position: 'relative' }} className="carousel-right-arrow" > <ArrowRightShort className="arrow-carousel"/> </div>}
                  >
                    {mainPropertyList.map((d, index) => (
                      <div key={ 'key-' + index }className="stacked-card" style={{ backgroundImage: 'url(' + d.property_images[0].url + ')' }} onClick={ () => window.open(`/desarrollos/${ d.public_id.split("-")[1] }`, "_self")}>
                      </div>
                    ))}
                  </StackedCarousel>
                  
                  {/*<AutoplaySlider
                    animation="openAnimation"
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={5000}
                    className='carousel-item-style'
                    onTransitionEnd={ (e) => onChangeSlider( e ) }>

                    {mainPropertyList.map((d) => (
                      <div className="right">
                        <img src={ d.title_image_full } />
                      </div>
                    ))}
                  </AutoplaySlider>*/}
                </>
              }

              </Col>
            </Row>
          </Container>
        </div>
        </div>

        <div ref={ refFoo }>
        <div className="p-container" style={{ paddingBottom: 0 }} align="center" ref={ refContact }>
          <div className="p-contact">
            <Container>
              <Row>
                <Col md={ 8 }>
                  <div className="contact-title"> HAGAMOS CRECER EL <span className="f-title-stroke">PROYECTO DE TUS</span> SUEÑOS</div>
                </Col>
                <Col md={ 4 }>
                  <br/>
                  <Form id="contactForm" onSubmit={ handleOnClick }>
                    <Form.Group className="m-3">
                      <Form.Control required name="name" value={name} placeholder="NOMBRE:" onChange={ (e) => setName(e.target.value) } />
                    </Form.Group>

                    <Form.Group className="m-3">
                      <Form.Control required type="email" value={email} name="email" placeholder="CORREO:" onChange={ (e) => setEmail(e.target.value) } />
                    </Form.Group>

                    <Form.Group className="m-3">
                      <Form.Control required type="number" value={phone} name="phone" placeholder="TELÉFONO:" onChange={ (e) => setPhone(e.target.value) } />
                    </Form.Group>

                    <Form.Group className="m-3">
                      <Form.Control required name="comments" as="textarea" value={comments} rows="2" placeholder="COMENTARIOS:" onChange={ (e) => setComments(e.target.value) } />
                    </Form.Group>

                    <div align="right" className="m-3">
                      <Button disabled={isLoading} variant="outline-primary" className="sent-button" type="submit">{isLoading ? 'ENVIANDO…' : 'ENVIAR'}</Button>
                    </div>
                  </Form>

                  <br/>
                  <div align="right">
                    {/* <div><TelephoneFill className="icon-style" /> &nbsp; <a href="tel:5625985051" className="link-style">56 2598 5051</a> </div>
                    <div><EnvelopeAtFill className="icon-style" /> &nbsp; <a href="mailto:contacto@inmobiplace.com" className="link-style">contacto@inmobiplace.com</a> </div> */}
                    <div><img src={ phoneIcon } className="icon-style-img" /> &nbsp; <a href="tel:5640025428" className="link-style">56 4002 5428</a> </div>
                    <div><img src={ correoIcon } className="icon-style-img" /> &nbsp; <a href="mailto:contacto@inmobiplace.com" className="link-style">contacto@inmobiplace.com</a> </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                    <div className="footer-image"> <img src={ contact } style={{ width: '100%' }}/> </div>
                </Col>
              </Row>
            </Container>
            <div className="footer-space"></div>
            <div id="bottomPage"></div>
            <div id="footerComponent">
              <Footer transparent={ true } />
            </div>
          </div>
        </div>
        </div>

      </div>

      <Modal show={showMessage} onHide={ () => setShowMessage(false) }>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={() => setShowMessage(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Propiedades;
