import React, {  useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { ArrowRight, Whatsapp } from "react-bootstrap-icons";
import inmobi from "../img/inmobifooter.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import mebs from '../img/grupomebs.png'

function Footer({ transparent, handleOnChangeNav }) {
  const location =  window.location.pathname ;
  const [ showPrivacy, setShowPrivacy ] = useState( false )

  useEffect(() => {
    //const estiloFooter = !isBlogPage ? "footer-tienes-exp" : "footer-font-tienes";
  }, []);

  return (
    <div>
      <div>
        <footer className={ transparent ? "section-footer section-footer-green" : "section-footer" }>
          <Container>
            <div className={ transparent ? "section-footer section-footer-green" : "section-footer" }>
              { !transparent && 
                <Row className="align-items-center">
                  <Col lg={ 6 } md={ 12 } xs={ 12 } className="footer-tienes-exp">
                    <span className='footer-text-style'>¿Te interesa?</span><span className='btn-download'>&nbsp;&nbsp;&nbsp;</span>
                    <span className="footer-contact" onClick={ () => handleOnChangeNav( 'contacto' ) } style={{ cursor: 'pointer' }}> Contáctanos <ArrowRight /> </span>
                  </Col>
                  {/* <Col md={ 'auto' }>
                    <div className="footer-font-contact" onClick={ () => handleOnChangeNav( 'contacto' ) } style={{ cursor: 'pointer' }}>
                      <span className="footer-contact">Contactanos</span>&nbsp;&nbsp;
                    </div>{" "}
                  </Col>*/}
                </Row>
              }
              <hr style={{ opacity: 1, borderTop: '3px solid #fff' }} />
              <Row className="align-items-center section-footer-top">
                <Col md={ 4 } xs={ 12 } className='footer-left'>
                  <a href='/'><img src={inmobi} className="footer-inmobil"/></a>
                </Col>
                <Col md={ 4 } xs={ 12 }>
                  <div onClick={ () => setShowPrivacy( true )} className='privacy-footer'>AVISO DE PRIVACIDAD</div>
                  <div> <a href="mailto:contacto@inmobiplace.com" className='mail-footer'> contacto@inmobiplace.com </a> </div>
                </Col>

                <Col md={ 4 } xs={ 12 } className='footer-right'>
                  <span className="text-mebs" style={{ verticalAlign: "center" }}> by </span>&nbsp;
                  <a href='https://grupomebs.com/' target='_blank'><span> <img src={ mebs } className='mebs' /> </span></a>
                </Col>
              </Row>
            </div>
          </Container>
          <div style={{ height: '10px' }} />
        </footer>
      </div>
      
      <Modal show={showPrivacy} size="lg" onHide={ () => setShowPrivacy(false) }>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#000", paddingLeft: "34px" }}>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", textAlign: "justify" }}>
          Por medio del presente aviso, GRUPO MEBS S.A. de C.V., con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, le informa y hace de su conocimiento que es responsable de recabar sus datos personales, del uso que se les dé a los mismos y de su protección con las siguientes finalidades: proveer la prestación de servicios y productos que se soliciten, notificar sobre promociones y novedades en cuanto a servicios y productos relacionados con los ya contratados o que sean contratados o adquiridos posteriormente, evaluar la calidad del servicio que brindamos y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
          <br/><br/>
          Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
          <br/><br/>
          Nombre.<br/>
          Número telefónico.<br/>
          Correo electrónico.<br/>
          <br/><br/>
          Es importante hacerle de su conocimiento, que en cualquier momento tiene el derecho de acceso, rectificación y cancelación de sus datos personales, así como a la revocación del consentimiento que nos ha otorgado para los fines señalados con anterioridad, para lo que será necesario que envíe una solicitud al número telefónico de contacto: 56 2598 5051 o vía correo electrónico en contacto@inmobiplace.com.
            Aplica para el caso de no desear recibir mensajes promocionales.
          <br/><br/>
          Fecha de actualización: 10/05/2022
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShowPrivacy(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Footer;
