import React, {  useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import Desarrollos from "./pages/Desarrollos";
import Detail from './pages/Detail';
import Propiedades from './pages/Propiedades';
//import HeaderReal from "./pages/HeaderReal";
import Footer from './pages/Footer';

import Container from 'react-bootstrap/Container';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import logo_nav from "./img/logo.png";
import logo from './img/inmobi_profile.png'

const navPath = [ 'nosotros', 'contacto' ]

function App() {
  const [ showHeaderFooter, setShowHeaderFooter ] = useState( true )
  const [ isHome, setIsHome ] = useState( '' )
  const [ path, setPath ] = useState( window.location.pathname.replace(/\//g, '') )
  const [ sectionPath, setSectionPath ] = useState( null )

  const nosotrosRef = useRef()
  const contactoRef = useRef()

  const location =  window.location.pathname ;
  const isReal = location == "/propiedades" || location.includes("/desarrollos");
  
  useEffect(() => {
    var home = window.location.pathname  === "/inicio" || window.location.pathname  === "/"
    setIsHome( home )
    setShowHeaderFooter( home ? false : true )

    if( sessionStorage.getItem('section') ) {
      var section = sessionStorage.getItem('section')

      var element = eval(section + 'Ref').current;
      var headerOffset = 86;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      setTimeout( () => {
        var element = eval(section + 'Ref').current;
        var headerOffset = 86;
        if(section == 'nosotros') headerOffset = headerOffset + 200
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        })
      }, 
        1000
      );

      /*eval(section + 'Ref').current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })*/

      sessionStorage.removeItem( 'section' )
    }
  });

  const handleOnChangeNav = ( section ) => {
    if( navPath.includes( section ) && path != '' && path != '/' ) {
      sessionStorage.setItem('section', section);
      window.location.href = '/'
    } else {
      //console.log("go to section", section)
    
      setTimeout( () => {
        var element = eval(section + 'Ref').current;
        var headerOffset = 86;
        if(section == 'nosotros') headerOffset = headerOffset + 200
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        })
      }, 
        0
      );
    
      /*eval(section + 'Ref').current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })*/
    }
  }

  return (
    <div>

      <FloatingWhatsApp 
        phoneNumber="5215640025428"
        accountName="Inmobiplace"
        className="wa-style"
        statusMessage="En linea"
        avatar={ logo }
        chatMessage="Hola, ¡Bienvenido a Inmobiplace! Nos alegra que nos escribas. ¿Cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />
     
      {/*<HeaderReal showHeaderFooter={ true } />*/}
      <header className="header-real">
        <Container fluid>
          <Navbar expand="lg" fixed="top" className='navbar-custom' variant="dark">
            <Container fluid>
              <Navbar.Brand href="/">
                <img src={logo_nav} height="60" className="d-inline-block align-top logo" alt="React Bootstrap logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav className="align-items-center">
                  <Nav.Link href="/" className="nav-link-comp font-heder"> INICIO </Nav.Link>
                  <Nav.Link onClick={ () => handleOnChangeNav( 'nosotros' ) } className="nav-link font-heder"> NOSOTROS </Nav.Link>
                  <Nav.Link href="/desarrollos?offer=&ptype=" className="nav-link font-heder"> PROPIEDADES </Nav.Link>
                  <Nav.Link onClick={ () => handleOnChangeNav( 'contacto' ) } className="nav-link header-contact"> CONTACTO </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </header>

      <div className='header-space'/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Propiedades refUs={ nosotrosRef } refContact={ contactoRef }/>} />
          <Route path="desarrollos" element={<Desarrollos />} />
          <Route path="desarrollos/:id" element={<Detail />} />
        </Routes>
      </BrowserRouter>


      { showHeaderFooter && 
        <Footer transparent={ false } handleOnChangeNav={ handleOnChangeNav } />
      }
    
    </div>
  );
}

export default App;
